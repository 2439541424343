<template>
  <div 
    class="
    d-flex 
    flex-column
    flex-grow-1
    overflow-lg-hidden
    overflow-auto">
    <div class="navbar d-flex">
      <div class="logo">
        <img src='@/assets/icons/ihs-logo.svg' alt="Logo IHS">
      </div>

      <div class="cashier-info dropdown ms-auto">
        <div >
            <img class="profile-picture avatar" :src="Object.keys(data.user).length ? imageUser(data.user.image, data.user.gender) : require('@/assets/illustration/empty-profile-male.svg')" alt="">
        </div>

        <div class="info">
          <span class="font-roboto font-16 my-auto"> {{Object.keys(data.user).length ? data.user.name : 'Guest User'}}</span>
        </div>
      </div>
    </div>

    <div class="title d-flex pb-3 m-4">
      <div class="d-flex font-nunito font-700 font-24">
        <div>
          <span>
            Item Belanja
          </span>
        </div>
        <div class="counter">
          {{totalProduct}}
        </div>
      </div>
      <div class="font-roboto font-400 ms-auto my-auto">
         {{new Date().toISOString().slice(0, 10)}}
      </div>
    </div>

    <div class="h-100 d-flex flex-grow-1 px-4 overflow-auto w-100">
      <div class="d-flex flex-column gap-4 w-100">          
          <div 
            v-for="product in data.cart.products" 
            :key="product.product_detail_id"
             class="wrapper d-flex justify-content-between align-items-start">
             <div class="d-flex gap-3 product-name">
               <div class="product-image my-auto" v-lazy-container="vLazyProductImage">
                 <img :data-src="product.files[0].value">
               </div>
               <div class="my-auto ">
                 <div>
                   <span class="font-16 font-500">
                     {{product.name}}
                   </span>
                 </div>
                 <span v-if="product.type === 'combination'" class="font-14 font-400 color-grey-70">
                   Variasi : {{product.variant_name}}
                 </span>
               </div>
             </div>
            <div class="my-auto product-price ">
              <div class="d-flex flex-column font-16 font-400 font-roboto">
                <span class="color-grey-70">
                  <s v-if="product.discount_amount">
                    {{formatRupiah(product.discount_amount, 'Rp. ')}}
                  </s>
                </span>
    
                <span>
                  {{
                      formatRupiah(
                      useGroceryPrice ? productPriceDetail(product).price :
                      product.discounted_price ? product.discounted_price :
                      product.price, 'Rp.'
                      ) 
                  }}
                </span>
              </div>
            </div>
            <div class="product-quantity my-auto bg-primary-light color-primary font-500">
              {{product.quantity}}
            </div>
            <div class="d-flex my-auto product-subtotal" >
              <span class="font-20 font-500 my-auto font-roboto">
                {{formatRupiah(( useGroceryPrice ? productPriceDetail(product).price :
                product.discounted_price ? product.discounted_price :
                product.price) * product.quantity, 'Rp')}}
              </span>
            </div>
          </div>
      </div>
    </div>

    <div class="buttom-navigation d-flex">
      <div 
        class="
        buttom-navigation__info 
        d-flex flex-column
        flex-grow-1
        font-roboto font-16">
        <div class="font-400 d-flex mb-2">
          <div>
            <span>
              Subtotal {{totalProduct}} Barang
            </span>
          </div>
          <div class="ms-auto">
            <span>{{formatRupiah(totalPrice, 'Rp')}}</span>
          </div>
        </div>
             <div class="d-flex mb-2" v-if="data.additional_cost">
          <div>
            <span class="">Lainnya</span>
          </div>
          <div class="ms-auto my-auto">
            <span>{{formatRupiah(data.additional_cost, 'Rp. ')}}</span>
          </div>
        </div>
        <div class="d-flex mb-2" v-if="data.point_cost">
            <div>
            <span >Potongan Poin</span>
            </div>
            <div class="ms-auto my-auto">
              <span>-{{formatRupiah(data.point_cost, 'Rp. ')}}</span>
            </div>
          </div>
        <div class="font-500 d-flex mb-2" v-if="Object.keys(data.cart.voucher).length">
          <div>
            <span class="font-20 color-blue-dark-50">{{data.cart.voucher.code}}</span>
          </div>
          <div class="ms-auto my-auto">
            <span>-{{formatRupiah(voucherAmmount, 'Rp. ')}}</span>
          </div>
        </div>
     
      </div>
      <div class="total font-roboto font-400 d-flex ms-auto">
        <div class="d-flex flex-column my-auto">
          <div class="font-roboto font-500 font-16" data-target="#successPaymentPopup" data-toggle="modal">
            Total {{totalProduct}} Barang
          </div>
          <div class="font-nunito font-700 font-28 ">
            <span>
              <span>{{formatRupiah(calculatedPrice, 'Rp')}}</span>
            </span>
          </div>
          <div class="bg-primary-light color-black  point-container pe-4 mt-2">
            <span class="text-primary font-500">{{Math.floor((calculatedPrice) / 25000) * 250}}</span> poin akan didapatkan
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'
import productMixin from '@/mixins/product.mixins.js'

import { mapActions } from 'vuex'


export default {
  mixins: [IhsMixin, productMixin],

  data() {
    return {
      data: {
        cart: {
          products: [],
          voucher: {}
        },
        additional_cost : 0,
        point_cost: 0,
     
        user: {},
      },
      useGroceryPrice : false
    }
  },
  computed: {
    totalProduct () {
      let total = 0
      if (this.data.cart.products.length) {
        this.data.cart.products.forEach(product => {
          total += product.quantity
        })
      }

      return total
    },
    totalPrice() {
      let total = 0

       if (this.data.cart.products.length) {
        this.data.cart.products.forEach(product => {
           total += ( this.useGroceryPrice ? this.productPriceDetail(product).price :product.discounted_price ? product.discounted_price :product.price) * product.quantity, 'Rp'
        })
      }
      
      return total
    },

    voucherAmmount() {
      let voucher = this.data.cart.voucher
      if(Object.keys(voucher).length){
        if(voucher.reduction_type === "percent"){
          let discount = (voucher.value/100)*this.totalPrice
          return discount
        } else {
          return voucher.value
        }
      } else {
        return 0
      }
    },
    cashier() {
			return JSON.parse(localStorage.activeCashier);
		},
    calculatedPrice() {
      let calculatedPrice = this.totalPrice

      if(Object.keys(this.data.cart.voucher).length) {
        calculatedPrice -= this.voucherAmmount > 10000 ? this.voucherAmmount : 10000
      }

      if(this.data.additional_cost) {
        calculatedPrice += this.data.additional_cost
      }

      if(this.data.point_cost) {
        calculatedPrice -= this.data.point_cost
      }

      return calculatedPrice
    },
  },
  methods: {
    ...mapActions('cart', ['getCart']),
    ...mapActions('user', ['getCustomer'])
  },
 async mounted () {


    window.Echo.channel('cashier-cart-' + this.cashier.id ).listen('.cashier-cart', (response) => {
    
      this.data = {
        ...this.data,
        cart: {
          ...response.cart,
          voucher:  response.cart.vouchers.length ? response.cart.vouchers[0] : {}
        },
        user: response.user,
      }

    })

    window.Echo.channel('cashier-cart-additional-cost-' + this.cashier.id ).listen('.additional-cost', (response) => {
      console.log(response)
      this.data = {
        ...this.data,
        ...response
      }

    })

    window.Echo.channel('cashier-cart-point-cost-' + this.cashier.id ).listen('.point-cost', (response) => {
      
      this.data = {
        ...this.data,
        ...response
      }

    })

    window.Echo.channel('cashier-cart-grocery-price-' + this.cashier.id ).listen('.grocery-price', (response) => {
    
    this.useGroceryPrice = response.grocery_price
  
 
  })




  }

}
</script>

<style lang="scss" scoped>
.title, .product{
  border-bottom: 1px solid #DADADA;
}

.product-name {
  width: 500px;
}

.product-price {
  width: 175px;
}

.product-quantity {
  padding: 4px 8px;
}

.product-subtotal {
  width: 140px;
}

.buttom-navigation{
  box-shadow: 0px -6px 8px 0px #0000001A;

  &__info{
    padding: 18px 27px 18px 24px;
  }

  .total{
    box-shadow: 0px -6px 8px 0px #0000001A;

    background: #006CB5;
    color: white;
    width: 300px;
    padding: 17.5px 0px 17.5px 37px;
  }
}

.point-container {
  padding: 6px 12px 6px 10px;
  border-radius: 6px;
}
</style>